<template>
	<Modal v-model="showFeedback" width="1024">
		<p slot="header" style="text-align:center;font-size: 24px;font-weight: 500;height: 48px;line-height: 48px;">
			<span>您的建议</span>
		</p>
		<div>
			<Form :model="form" label-position="left" :label-width="100">
				<FormItem label="您期望的单价">
					<Input v-model="form.assessUnitPrice"><span slot="append">元/平方米</span></Input>
				</FormItem>
				<FormItem label="您期望的总价">
					<Input v-model="form.assessTotalPrice"><span slot="append">万元</span></Input>
				</FormItem>
				<FormItem label="建议">
					<Input maxlength="1000" type="textarea" :rows="5" show-word-limit
						v-model="form.feedbackReason"></Input>
				</FormItem>
			</Form>
		</div>
		<div slot="footer">
			<!-- <Button type="success" @click="show">look</Button> -->
			<Button type="success" v-track="{event:'click',route:$route,isButton:true,log:'用户反馈-提交'}"
				@click="ok(FeedbackState)">提交</Button>
		</div>
	</Modal>
</template>

<script>
	import {
		addDataUserFeedback
	} from '@/api/pledge.js'
	export default {
		props: {
			feedbackModel: {
				type: Boolean,
				required: true,
			},
			feedbackObj: {
				type: Object
			},
			feedbackState: {
				type: String
			}
		},
		data() {
			let _this = this
			return {
				form: {
					assessUnitPrice: '',
					assessTotalPrice: '',
					feedbackReason: ''
				},
				FeedbackState : 0
			}
		},
		computed: {
			showFeedback: {
				get() {
					return this.feedbackModel
				},
				set(newVal) {
					this.$parent.showFeedbackModal = newVal
				}
			},
		},
		watch: {
			feedbackObj: {
				handler(newVal, oldVal) {
					this.form.assessUnitPrice = (newVal.assessUnitPrice * 1).toFixed(0)
					this.form.assessTotalPrice = (newVal.assessTotalPrice * 1 / 10000).toFixed(2)
				},
				deep: true,
			},
			showFeedback(newVal) {
				if (newVal) {
					this.form.assessUnitPrice = (this.feedbackObj.assessUnitPrice * 1).toFixed(0)
					this.form.assessTotalPrice = (this.feedbackObj.assessTotalPrice * 1 / 10000).toFixed(2)
				}
			},
			feedbackState(val){
				this.FeedbackState = val
				this.form.feedbackReason = ''
			}
		},
		methods: {
			async ok(value) {
				if (this.FeedbackState !== 0) {
					if (this.form.assessTotalPrice == '' || this.form.assessUnitPrice == '') {
						this.$msg.error({
							text: '请输入价格/总价'
						});
						return
					}
					let params = {
						assessBaseDate: this.feedbackObj.assessBaseDate,
						feedbackReason: this.form.feedbackReason,
						feedbackStates: value,
						feedbackTotalPrice: (this.form.assessTotalPrice * 1).toFixed(0),
						feedbackUnitPrice: (this.form.assessUnitPrice * 1).toFixed(0),
						sampleResultId: this.feedbackObj.sampleResultId,
						certificateMd5: this.feedbackObj.certificateMd5,
						sampleHistoryId: this.feedbackObj.sampleHistoryId,
						platform: '1'
					}
					addDataUserFeedback(params).then(res => {
						this.$msg.success({
							text: '谢谢'
						});
						this.showFeedback = false
					}).catch(err => {
						this.$msg.error({
							text: this.$errorCode[err.code]
						});
					})
				}
			},
			show() {
			}
		}

	}
</script>

<style scoped>

</style>
