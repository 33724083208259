<template>
	<div v-track="{ event: 'browse', route: $route, isButton: false }" class="home">
		<!-- 跳转的锚点 -->
		<div id="houseProperty" ref="houseProperty"></div>
		<Row>
			<Col span="22" offset="1">
			<Row>
				<Col span="24">
				<Row>
					<Col class="title">估价对象</Col>
				</Row>
				<Row class="info" :gutter="16">
					<Col span="3"><Select @on-change='selectCity' v-model='area.shi' size="large">
						<Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
					</Select></Col>
					<Col span="12">
					<Select v-track="{ event: 'click', route: $route, isButton: false, log: '点击请输入小区名称输入框' }"
						placeholder="请输入小区名称" size="large" clearable :disabled='showInfo || !showDetail' v-model="communityName"
						@on-clear='clearData' filterable @on-query-change='onQueryChange' :remote-method="getCommunityList"
						@on-change='searchStreet' filter-by-label @on-select='selectCommunity' :loading="loading">
						<Option :label='option.label' v-for="(option, index) in communityList" :value="option.value" :key="index">
							<template v-if="!showHistory">
								<span style="display: inline-block;position: relative;width: 100%">
									<Icon type="ios-search-outline" />
									{{ option.label }}
									<Icon style='font-size: 18px;position: absolute;right: 0;top: 0;' type="ios-redo" />
								</span>
							</template>
							<template v-else>
								<span style="display: inline-block;position: relative;width: 100%;">
									<Icon type="ios-time-outline" />
									{{ option.label }}
									<Icon @click.stop="deleteSelectHistory(option)"
										style='font-size: 18px;position: absolute;right: 0;top: 0;' type="ios-close" />
								</span>
							</template>
						</Option>
					</Select>
					</Col>
					<!-- 					<Col span="2"><Button v-track="{event:'click',route:$route,isButton:true,log:'搜索'}" size="large"
						:disabled='showInfo' style="width:100%;" class="btn" type="primary"
						@click="searchStreet">搜索</Button></Col> -->
					<Col span="4"><Button v-track="{ event: 'click', route: $route, isButton: true, log: '找不到小区，请点击' }"
						size="large" class="noCommunity" type="default" @click="noCommunity = true">
						<span class="iconfont icon-jiqiren"></span>找不到小区，手动提交估价</Button></Col>
				</Row>
				</Col>
				<Col span="24">
				<Row>
					<Col class="title" span="12">{{ showCommunityName ? showCommunityName : '小区名称' }}</Col>
					<Col span="12">
					<Button v-if='canShowPrice' style="float: right;margin-right: 60px;margin-top: 37px;" size="large"
						v-track="{ event: 'click', route: $route, isButton: true, log: '房屋智能估价报告下载' }" @click="downLoadReport()"
						type="primary">下载报告</Button>
					</Col>
				</Row>
				<Row class="info">
					<Col span="11" v-if="!showInfo" class="chuck chuckLeft">
					<Form ref="adress" inline :label-width="80">
						<FormItem style="width: 100%" label="楼栋号">
							<Row style="margin-bottom: 10px">
								<Select v-track="{ event: 'click', route: $route, isButton: false, log: '点击请选择楼栋号' }" size="large"
									filterable :disabled='!showDetail' placeholder="请选择楼栋号" v-model="street" @on-change='searchUnit'>
									<Option v-for="(option, index)  in streetList" :value="option.label" :key="index">
										{{ option.label }}
									</Option>
								</Select>
							</Row>

						</FormItem>
						<FormItem style="width: 100%" label="房号">
							<Row :gutter="10">
								<!-- <Col span="6" v-if="unitList.length != 0 ">
								<Select v-track="{event:'click',route:$route,isButton:false,log:'点击请选择单元'}"
									v-model="unit" size="large" filterable placeholder="请选择单元"
									:disabled='unitList.length == 0 || !showDetail' @on-change='searchHouseNumber'>
									<Option v-for="(option, index)  in unitList" :value="option.label" :key="index">
										{{ option.label }}
									</Option>
								</Select></Col> -->
								<Col span="6">
								<Select v-track="{ event: 'click', route: $route, isButton: false, log: '点击请选择房号' }" clearable
									size="large" filterable :disabled='!showDetail' placeholder="请选择房号" v-model="houseNumber"
									@on-select='showHouse'>
									<Option v-for="(option, index)  in houseNumberList" :value="option.label" :key="index">{{ option.label
									}}</Option>
								</Select>
								</Col>
								<Col span="18" style="font-size: 16px;">找不到房屋，<span
									v-track="{ event: 'click', route: $route, isButton: true, log: '找不到房屋，请点击' }" class="zheli"
									@click="noCommunity = true">手动提交估价</span>
								</Col>
							</Row>
						</FormItem>
					</Form>
					<Form v-if="showDetail && houseNumber != undefined" ref="detail" inline :label-width="80">
						<FormItem :required='this.ypObj.buildingArea < 0' style="width: 50%;margin-bottom: 17px"
							v-if="this.ypObj.buildingArea || this.ypObj.buildingArea == 0" label="面积">
							<span v-if="this.ypObj.buildingArea > 0">{{ `${this.ypObj.buildingArea}平方米` }}</span>
							<Input type='number' v-else v-model="setBuildingArea" autofocus placeholder="请输入面积"
								style="width: 100%;margin-left: 15%;"><span slot="append">平方米</span>
							</Input>
						</FormItem>
						<FormItem style="width: 50%;margin-bottom: 17px" v-if="this.ypObj.propertyType" label="物业类型">
							{{ this.ypObj.propertyType }}
						</FormItem>
						<FormItem style="width: 50%;margin-bottom: 17px" v-if="this.ypObj.orientation" label="朝向">
							{{ this.ypObj.orientation }}
						</FormItem>
						<FormItem style="width: 50%;margin-bottom: 17px" v-if="this.ypObj.houseStyle" label="房屋户型">
							{{ this.ypObj.houseStyle }}
						</FormItem>
						<FormItem style="width: 50%;margin-bottom: 17px" v-if="this.ypObj.floorAt" label="楼层">
							{{ `${this.ypObj.floorAt}层` }}
						</FormItem>
						<FormItem style="width: 50%;margin-bottom: 17px" v-if="this.ypObj.totalFloor" label="总楼层">
							{{ `${this.ypObj.totalFloor}层` }}
						</FormItem>
						<FormItem style="width: 50%;margin-bottom: 17px" v-if="this.ypObj.activateYears" label="建成年代">
							{{ `${this.ypObj.activateYears}年` }}
						</FormItem>
						<FormItem style="width: 50%;margin-bottom: 17px" v-if="this.ypObj.decorationStatus" label="装修">
							{{ this.ypObj.decorationStatus }}
						</FormItem>
						<FormItem style="width: 100%; text-align: center;padding-right: 80px;">
							房屋信息有误或不全，<span v-track="{ event: 'click', route: $route, isButton: true, log: '修正和完善' }"
								@click="openDetailChoose" class="zheli">去修正和完善>></span>
						</FormItem>
					</Form>
					<Row>
						<Checkbox class='Checkbox' v-model="checked"> <span
								@click="showAdviceBookModel = true">【我已阅读《房屋智能估价系统询价建议书》】</span></Checkbox>
						<Button :loading='isShowGif' size="large"
							v-track="{ event: 'click', route: $route, isButton: true, log: '现在估价' }" @click="assessment()"
							v-if="showDetail && houseNumber != ''" class="btn assessmentBtn" type="primary">现在估价</Button>
						<p class="moreBtn changeFont" v-if="showDetail && houseNumber != ''">多套房屋估价，点击<span @click="goLink"
								v-track="{ event: 'click', route: $route, isButton: true, log: '多套房屋估价' }" class="zheli">批量</span></p>
					</Row>
					<Form v-if="!showDetail" ref="detailChoose" inline :model="originData" :label-width="80"
						style="text-align: left">
						<FormItem prop="buildingArea" style="width: 60%" label="面积">
							<Input type="number" size="large" v-model="originData.buildingArea">
							<span slot="append">平方米</span></Input>
						</FormItem>
						<FormItem style="width: 45%" label="物业类型">
							<Select size="large" v-model="originData.propertyType">
								<Option v-for="item in wylxList" :value="item.value" :key="item.value">{{ item.label }}
								</Option>
							</Select>
						</FormItem>
						<FormItem style="width: 45%" label="朝向">
							<Select size="large" v-model="originData.orientation">
								<Option v-for="item in cxList" :value="item.value" :key="item.value">{{ item.label }}
								</Option>
							</Select>
						</FormItem>
						<FormItem style="width: 80%" label="房屋户型">
							<Row :gutter="16">
								<Col span="6"><Select size="large" v-model="originData.roomAmount" placeholder="房">
									<Option v-for="item in 16" :value="item - 1" :key="item - 1">{{ `${item - 1}房` }}
									</Option>
								</Select></Col>
								<Col span="6"><Select size="large" v-model="originData.livingRoomAmount" placeholder="厅">
									<Option v-for="item in 16" :value="item - 1" :key="item - 1">{{ `${item - 1}厅` }}
									</Option>
								</Select></Col>
								<Col span="6"><Select size="large" v-model="originData.kitchenAmount" placeholder="厨">
									<Option v-for="item in 16" :value="item - 1" :key="item - 1">{{ `${item - 1}厨` }}
									</Option>
								</Select></Col>
								<Col span="6"><Select size="large" v-model="originData.toiletAmount" placeholder="卫">
									<Option v-for="item in 16" :value="item - 1" :key="item - 1">{{ `${item - 1}卫` }}
									</Option>
								</Select></Col>
							</Row>
						</FormItem>
						<FormItem style="width: 45%" label="楼层">
							<Select size="large" v-model="originData.floorAt" :disabled="originData.totalFloor == ''">
								<Option v-for="item in originData.totalFloor" :value="item" :key="item">{{ `${item}层` }}
								</Option>
							</Select>
						</FormItem>
						<FormItem size="large" style="width: 45%" label="总楼层">
							<Select size="large" v-model="originData.totalFloor">
								<Option v-for="item in 100" :value="item" :key="item">{{
										`${item}层`
								}}</Option>
							</Select>
						</FormItem>
						<FormItem style="width: 45%" label="建成年代">
							<DatePicker size="large" type="year" :options="dataLimit" placeholder="Select year" style="width: 200px"
								:value="originData.activateYears" v-model="originData.activateYears"></DatePicker>
						</FormItem>
						<FormItem style="width: 45%" label="装修">
							<Select size="large" v-model="originData.decorationStatus">
								<Option v-for="item in zxList" :value="item.value" :key="item.value">{{ item.label }}
								</Option>
							</Select>
						</FormItem>
					</Form>
					<Row style="text-align: center">
						<Button v-track="{ event: 'click', route: $route, isButton: true, log: '修正和完善-确定' }"
							@click="detailChooseSure" size="large" v-if="!showDetail" class="btn assessmentBtn"
							type="primary">确定</Button>
						<p class="moreBtn changeFont" v-if="!showDetail">多套房屋估价，点击<span
								v-track="{ event: 'click', route: $route, isButton: true, log: '多套房屋估价' }" @click="goLink"
								class="zheli">批量</span></p>
					</Row>
					<div v-if="isShowGif" class="loadingGif"></div>
					</Col>

					<!-- 详情 -->
					<Col span="11" v-if="showInfo" class="chuck chuckLeft">
					<Form style="border-bottom: 1px solid #e5e5e5;text-align: left;" inline :label-width="100">
						<FormItem style="width: 100%" label="估价对象">{{ this.ypDetail.certificateAddress }}</FormItem>
						<FormItem style="width: 100%" label="估价基准日">
							{{ this.ypDetail ? this.ypDetail.assessTime ? this.ypDetail.assessTime : this.ypDetail.gmtUpdated : '' |
									changeDate()
							}}
						</FormItem>
					</Form>
					<Row v-if='canShowPrice' class="priceBox">
						<Col span="12">
						<p class="priceInfo">价格</p>
						<p class="priceInfo priceDetail"><span class="price">{{ (ypDetail.assessUnitPrice * 1).toFixed(0)
						}}</span>元/平米
						</p>
						</Col>
						<Col span="12">
						<p class="priceInfo">总价</p>
						<p class="priceInfo priceDetail"><span class="price">{{ ypDetail.assessTotalPrice | changePrice()
						}}</span>万元
						</p>
						</Col>
						<Col span="24">
						<div class="referTips">
							注：以上为中思拓大数据系统AI自动估价，价格仅供用户参考。 “政府参考价”请查阅政府相关部门网站。
						</div>
						</Col>
					</Row>

					<Row v-else class="priceBox">
						<Col span="24">
						<div v-if='!hasReason' class="noPrice">
							正估价中...
						</div>
						<div v-else class="noPriceReason">
							<div class="text">
								暂无法估价
							</div>
							<div class="reason">
								原因：{{ ypDetail.reason }}
							</div>
						</div>
						</Col>
					</Row>


					<Row v-if='canShowPrice' :class='[ypDetail.officialGuideUnitPrice ? "" : "noOfficialGuideUnitPrice"]'>
						<p class="resultText">您的建议</p>
					</Row>
					<Row v-if='canShowPrice'>
						<Col span="8">
						<Button v-track="{ event: 'click', route: $route, isButton: true, log: '用户建议-偏低' }"
							style="width: 120px;margin-top: 15px;" type="primary" @click="feedBack(-1)" shape="circle">偏低</Button>
						</Col>
						<Col span="8">
						<Button v-track="{ event: 'click', route: $route, isButton: true, log: '用户建议-合理' }"
							style="width: 120px;margin-top: 15px;" type="primary" @click="feedBack(0)" shape="circle">合理</Button>
						</Col>
						<Col span="8">
						<Button v-track="{ event: 'click', route: $route, isButton: true, log: '用户建议-偏高' }"
							style="width: 120px;margin-top: 15px;" type="primary" @click="feedBack(1)" shape="circle">偏高</Button>
						</Col>
					</Row>
					<Row v-if='canShowPrice && ypDetail.officialGuideUnitPrice' class="officialGuidePriceBox">
						<Col span="12">
						<p class="officialGuidePrice">政府参考价</p>
						</Col>
						<Col span="12">
						<p class="officialGuidePrice price">
							<span>{{ ypDetail.officialGuideUnitPrice ? `${(ypDetail.officialGuideUnitPrice * 1).toFixed(0)}` : '--'
							}}</span>元/平米
						</p>
						</Col>
					</Row>


					<Row>
						<Checkbox class='Checkbox' v-model="checked"> <span
								@click="showAdviceBookModel = true">【我已阅读《房屋智能估价系统询价建议书》】</span></Checkbox>
						<Button :loading='isShowGif' size="large"
							v-track="{ event: 'click', route: $route, isButton: true, log: '再估一套' }" @click="assessment(true)"
							class="btn assessmentBtn" type="primary">再估一套</Button>
						<p class="moreBtn changeFont">多套房屋估价，点击<span
								v-track="{ event: 'click', route: $route, isButton: true, log: '多套房屋估价' }" @click="goLink"
								class="zheli">批量</span></p>
					</Row>
					</Col>
					<!-- 地图 -->
					<Col span="11" class="chuck" offset="1">
					<Row>
						<Col span="24">
						<div class="Bmap" id="Bmap"></div>
						</Col>
					</Row>
					<Row style="margin-top:30px">
						<Col span="24">
						<Tabs name="name" value="参考实例" type="card">
							<TabPane label="参考实例" name="参考实例" tab="name">
								<Tabs class="childTabs" v-model='activeTab' type="line" size="small" name="child">
									<TabPane label="挂牌实例" name="挂牌实例" tab="child">
										<Table :columns="GPcolumns" height="240" :data="GPdata"></Table>
									</TabPane>
									<TabPane label="成交实例" name="成交实例" tab="child">
										<Table :columns="CJcolumns" height="240" :data="CJdata"></Table>
									</TabPane>
								</Tabs>
							</TabPane>
							<TabPane label="周边配套" disabled name="周边配套" tab="name">周边配套</TabPane>
							<TabPane style="max-height: 300px;overflow-y: auto;" label="估价房屋详情" name="估价房屋详情" tab="name">
								<Form :label-width="80" style="text-align:center;" inline>
									<FormItem v-if="!this.ypObj.certificateAddress" class="ypObjItemNoData">
										<img src="../../assets/image/noData.png" alt="">
									</FormItem>
									<FormItem v-if="this.ypObj.buildingArea >= 0" class="ypObjItem" label="面积">
										{{ `${this.ypObj.buildingArea}平方米` }}
									</FormItem>
									<FormItem v-if="setBuildingArea != '' && this.ypObj.buildingArea < 0" class="ypObjItem" label="面积">
										{{ `${setBuildingArea}平方米` }}
									</FormItem>
									<FormItem v-if="this.ypObj.propertyType" class="ypObjItem" label="物业类型">
										{{ this.ypObj.propertyType }}
									</FormItem>
									<FormItem v-if="this.ypObj.orientation" class="ypObjItem" label="朝向">
										{{ this.ypObj.orientation }}
									</FormItem>
									<FormItem v-if="this.ypObj.houseStyle" class="ypObjItem" label="房屋户型">
										{{ this.ypObj.houseStyle }}
									</FormItem>
									<FormItem v-if="this.ypObj.floorAt" class="ypObjItem" label="楼层">
										{{ `${this.ypObj.floorAt}层` }}
									</FormItem>
									<FormItem v-if="this.ypObj.totalFloor" class="ypObjItem" label="总楼层">
										{{ `${this.ypObj.totalFloor}层` }}
									</FormItem>
									<FormItem v-if="this.ypObj.activateYears" class="ypObjItem" label="建成年代">
										{{ `${this.ypObj.activateYears}年` }}
									</FormItem>
									<FormItem v-if="this.ypObj.decorationStatus" class="ypObjItem" label="装修">
										{{ this.ypObj.decorationStatus }}
									</FormItem>
								</Form>
							</TabPane>
						</Tabs>
						</Col>
					</Row>
					</Col>
				</Row>
				</Col>
				<Col span="24">
				<Row>
					<Col class="title">我的估价</Col>
				</Row>
				<Row class="info">
					<Col span="24">
					<Form ref="search" class="changeFont" inline :label-width="80">
						<FormItem style="width: 20%" label="估价对象">
							<Input size="large" placeholder="请输入" v-model="getHistoryForm.certificateAddress"></Input>
						</FormItem>
						<FormItem style="width: 20%" label="估价时间">
							<DatePicker :editable='false' size="large" :options="dataLimit2" @on-change='getHistorySelectDate'
								type="daterange" placement="bottom-end" placeholder="选择日期"></DatePicker>
						</FormItem>
						<FormItem>
							<Button class="btn searchBtnLog" data-id="1" size="large" type="primary"
								v-track="{ event: 'click', route: $route, isButton: true, log: '我的估价-查询' }"
								@click="getHistory(1)">查询</Button>
						</FormItem>
					</Form>
					</Col>
				</Row>
				<Row style="margin-bottom:100px">
					<Col span="24">
					<Table :columns="Mycolumns" :data="Mydata">

					</Table>
					</Col>
					<Col span="10" offset="14">
					<Page class="page" :current="getHistoryForm.current" :total="totalPage" @on-change='changePage' />
					</Col>
				</Row>
				</Col>
			</Row>
			</Col>
			<Modal v-model="isShowWxModel" @on-visible-change="wxModalChange" :mask-closable="false" :footer-hide="true"
				title="温馨提示" @on-ok="ok">
				<div class="wx-box">
					<div class="wx-title">广州房屋智能估价升级中，可以扫码进入小程序估价</div>
					<div class="wx-img">
						<img src="../../assets/image/wxcode.jpg" width="240" height="240" alt="" srcset="">
					</div>
					<div class="wx-desc">微信扫码</div>
					<Button class="wx-bit" type="primary" @click="isShowWxModel = false">确定</Button>
				</div>
			</Modal>
		</Row>

		<adviceBookModel :adviceBookModel='showAdviceBookModel'></adviceBookModel>
		<feedbackModal ref="feedbackModal" :feedbackModel='showFeedbackModal' :feedbackObj="ypDetail"
			:feedbackState="feedbackState">
		</feedbackModal>

		<disclaimerModal :disclaimerModal='disclaimerModal'></disclaimerModal>

		<!-- 找不到小区/房屋 -->
		<NoCommunity :noCommunity='noCommunity' :cityCode='area.shi' :communityName='communityName'
			@getHistory='getHistory'></NoCommunity>

		<ReportModal :showReport='showReport' :reportJson='reportJson' @showReport="setReportState"></ReportModal>
		<!-- <Spin size="large" fix v-if="spinShow"></Spin> -->
	</div>
</template>

<script>
// @ is an alias to /src
import BMap from "BMap";
import _ from 'lodash';
import {
	communitySelectCondition,
	certificateDetail_street,
	certificateDetail_unit,
	certificateDetail_houseNumber,
	sampleResultDetail,
	certificateEdit,
	exampleList,
	evaluationHistoryPage,
	certificateApply,
	certificateById,
	historyById,
	communitySearchHistory,
	deleteHistory,
	saveCommunitySearchHistory
} from "@/api/pledge";
import {
	data_user_operation_log_in
} from '@/api/operationLog.js'
import {
	formatTime,
	formatDate,
	_debounce
} from "@/utils/";
import adviceBookModel from '@/components/adviceBookModel.vue'
import feedbackModal from '@/components/feedback.vue'
import disclaimerModal from "@/components/modal/disclaimerModal";
import NoCommunity from '@/components/NoCommunity.vue'
import ReportModal from '@/components/ReportModal.vue'
import CityList from './cityList.js'
import dataReports from "@/mixins/dataReports";
export default {
	name: "houseProperty",
	components: {
		adviceBookModel,
		feedbackModal,
		disclaimerModal,
		NoCommunity,
		ReportModal,
	},
	mixins: [dataReports],
	filters: {
		changePrice: function (value) {
			if (value == '--') return '--'
			return `${(value / 10000).toFixed(2)}`
		},
		changeDate(value) {
			if (value) {
				return formatDate(value * 1)
			} else {
				return ''
			}
		},

	},
	computed: {

		showHouseInfo() {
			if (this.showDetail && this.houseNumber != '') {
				return true
			} else {
				return false
			}

		},
		showAdviceModal: {
			get() {
				return this.$store.state.modal.showAdviceModal;
			},
			set(newValue) {
				this.$store.state.modal.showAdviceModal = newValue;
			}
		},
		addAdviceData: {
			get() {
				return this.$store.state.modal.addAdviceData;
			},
			set(newValue) {
				this.$store.state.modal.addAdviceData = newValue;
			}
		},
	},
	data() {
		let that = this

		return {
			//显示微信小程序弹窗
			isShowWxModel: true,
			//是否显示历史记录
			showHistory: true,
			lockBtn: false,
			spinShow: false,
			//手动输入的面积
			setBuildingArea: '',
			activeTab: '挂牌实例',
			disclaimerModal: false,
			showFeedbackModal: false,
			feedbackState: '',
			//是否勾选
			checked: false,
			//显示建议书
			showAdviceBookModel: false,


			//显示的小区名称
			showCommunityName: '',
			//获取回来的原始数据，用于修改和完善
			originData: {},
			//估一估时返回的数据
			ypDetail: {
				certificateAddress: '',
				assessTime: '',
				canShow: false,
			},
			//选择房号时返回的数据
			ypObj: {},

			//级联返回的房号list
			houseDetaillist: [],
			houseNumber: undefined,
			//处理后可选择的房号list
			houseNumberList: [],
			//单元号
			unit: '',
			//单元list
			unitList: [],
			//街道名
			street: '',
			//街道list
			streetList: [],
			//输入时的加载状态
			loading: false,
			//小区名字
			communityName: '',
			//小区ID
			communityId: '',
			//模糊查询小区列表
			communityList: [],
			//区域信息
			area: {
				sheng: '440000000000',
				shi: '440100000000',
				qu: ''
			},
			cityList: [],
			//时间限制
			dataLimit: {
				disabledDate(date) {
					return (
						date.valueOf() > Date.now() - 86400000 ||
						date.valueOf() < 315504000000
					);
				},
			},
			//时间限制
			dataLimit2: {
				disabledDate(date) {
					return (
						date.valueOf() > Date.now()
					);
				},
			},
			//修正和完善信息
			showDetailChoose: {
				area: "", //平方米
				wylx: "", //物业类型
				cx: "", //朝向
				fwhxFang: "", //房间数
				fwhxTing: "", //厅数
				fwhxChu: "", //厨房数
				fwhxWei: "", //卫生间数
				szlc: "", //楼层
				zlc: "", //总楼层
				jcnd: new Date(), //建成年代
				zx: "", //装修
			},
			//修正和完善信息时间选择
			jcndSelectTime: '',
			//物业类型list
			wylxList: [{
				value: "住宅",
				label: "住宅",
			},
			{
				value: "别墅",
				label: "别墅",
			},
			],
			//朝向list
			cxList: [{
				value: "东",
				label: "东",
			},
			{
				value: "南",
				label: "南",
			},
			{
				value: "西",
				label: "西",
			},
			{
				value: "北",
				label: "北",
			},
			{
				value: "东南",
				label: "东南",
			},
			{
				value: "东北",
				label: "东北",
			},
			{
				value: "西南",
				label: "西南",
			},
			{
				value: "西北",
				label: "西北",
			},
			{
				value: "东西",
				label: "东西",
			},
			{
				value: "南北",
				label: "南北",
			},
			],
			//装修list
			zxList: [{
				value: "毛坯",
				label: "毛坯",
			},
			{
				value: "简装",
				label: "简装",
			},
			{
				value: "精装",
				label: "精装",
			},
			],
			noCommunity: false,
			showReport: false,
			reportJson: '',
			showDetail: true,
			//地图
			map: null,
			// 地图标注icon图片
			liveIcon: require("@/assets/image/map/local.png"),
			liveIconActive: require("@/assets/image/map/live.png"),
			closeIcon: require("@/assets/image/map/close.png"),
			overlayCloseIcon: require("@/assets/image/map/overLay_close.svg"),
			//地图信息
			mapData: {
				zoom: 17,
				center: {
					lng: 113.335593,
					lat: 23.119767,
				},
			},
			//成交案例数据
			CJdata: [],
			//成交案例表头
			CJcolumns: [{
				title: "成交时间",
				key: "transactionDate",
				width: '120',
				align: 'center',
				render: (h, params) => {
					return h(
						"span",
						params.row.transactionDate ?
							`${params.row.transactionDate.replace(/-/g, '/')}` : params.row.transactionDate
					);
				},
			},
			{
				title: "面积（平方米）",
				key: "buildingArea",
				width: '180',
				align: 'center',
				render: (h, params) => {
					return h(
						"span",
						params.row.buildingArea ? `${(params.row.buildingArea * 1).toFixed(2)}` : params
							.row.buildingArea
					);
				},

			},
			{
				title: "房屋户型",
				key: "houseStyle",
				width: '180',
				align: 'center',
			},
			{
				title: "价格（元/平米）",
				key: "unitPrice",
				width: '180',
				align: 'center',
				render: (h, params) => {
					return h(
						"span",
						params.row.unitPrice ? (params.row.unitPrice * 1).toFixed(0) : params.row
							.unitPrice
					);
				},
			},
			{
				title: "总价（万元）",
				key: "totalPrice",
				width: '150',
				align: 'center',
				render: (h, params) => {
					return h(
						"span",
						params.row.totalPrice ? (params.row.totalPrice * 1).toFixed(0) : params.row
							.totalPrice
					);
				},
			},
			],
			//挂牌案例数据
			GPdata: [],
			//挂牌案例表头
			GPcolumns: [{
				title: "挂牌时间",
				key: "publishDate",
				width: '120',
				align: 'center',
				render: (h, params) => {
					return h(
						"span",
						params.row.publishDate ? `${params.row.publishDate.replace(/-/g, '/')}` : params
							.row.publishDate
					);
				},
			},
			{
				title: "面积（平方米）",
				key: "buildingArea",
				width: '180',
				align: 'center',
				render: (h, params) => {
					return h(
						"span",
						params.row.buildingArea ? `${(params.row.buildingArea * 1).toFixed(2)}` : params
							.row.buildingArea
					);
				},
			},
			{
				title: "房屋户型",
				key: "houseStyle",
				width: '180',
				align: 'center',
			},
			{
				title: "价格（元/平米）",
				key: "unitPrice",
				width: '180',
				align: 'center',
				render: (h, params) => {
					return h(
						"span",
						params.row.unitPrice ? (params.row.unitPrice * 1).toFixed(0) : params.row
							.unitPrice
					);
				},
			},
			{
				title: "总价（万元）",
				key: "totalPrice",
				width: '150',
				align: 'center',
				render: (h, params) => {
					return h(
						"span",
						params.row.totalPrice ? (params.row.totalPrice * 1).toFixed(0) : params.row
							.totalPrice
					);
				},
			},
			],
			//我的估价数据
			Mydata: [],
			//我的估价表头
			Mycolumns: [{
				title: "估价对象",
				key: "certificateAddress",
				align: 'left',
				render: (h, params) => {
					return h(
						"span", {
						style: {
							color: "#203c85",
							cursor: "pointer",
						},
						on: {
							click: () => {
								that.spinShow = true
								let _params = {
									event: 'click',
									isButton: false,
									log: JSON.stringify(params.row),
									os: navigator.userAgent,
									platform: '数据平台PC',
									route: that.$route.path,
									title: that.$route.meta.title,
									token: that.$store.state.token ? that.$store.state
										.token : '',
									user_id: that.$store.state.userId ? that.$store
										.state
										.userId : '',
								}
								data_user_operation_log_in(_params)
								params.row.sampleHistoryId = params.row.id
								that.$router.push({
									name: '房产智估',
									// path: "/Empowerment/pledge/houseProperty",
									query: {
										id: params.row.id,
										// feedBack: JSON.stringify(params.row)
									},
									params: {
										id: params.row.id,
										feedBack: JSON.stringify(params.row)
									}
								});
								// } else if (params.row.assessResult == 2) {
								// 	this.$msg.success({
								// 		text: '暂时无法估价'
								// 	});
								// } else {
								// 	this.$msg.success({
								// 		text: '正在估价中'
								// 	});
								// }
							},
						},
					},
						params.row.certificateAddress
					);
				},
			},
			{
				title: "价格（元/平米）",
				key: "assessUnitPrice",
				align: 'center',
				render: (h, params) => {
					return h(
						"span",
						params.row.assessResult == 1 ? (params.row.assessUnitPrice * 1).toFixed(0) :
							params.row.assessResult == 0 ? '正估价中' : '暂无法估价'
					);
				},

			},
			{
				title: "总价（万元）",
				key: "assessTotalPrice",
				align: 'center',
				render: (h, params) => {
					return h(
						"span",
						params.row.assessResult == 1 ? (params.row.assessTotalPrice / 10000).toFixed(
							2) : params.row.assessResult == 0 ? '正估价中' : '暂无法估价'
					);
				},
			},
			{
				title: "估价时间",
				key: "assessTime",
				align: 'center',
				render: (h, params) => {
					return h(
						"span",
						params.row.assessTime ? formatTime(params.row.assessTime, '/') : formatTime(
							params.row.gmtUpdated, '/')
					);
				},
			},
			],
			//我的估价参数
			getHistoryForm: {
				current: 1,
				size: 10,
				certificateAddress: '',
				assessTimeStart: '',
				assessTimeEnd: '',
				// displayPlatform: 0,
			},
			totalPage: 1,
			//修改和完善的校验
			// originDataRules: {
			//     buildingArea: [{
			//         required: true,
			//         validator: validateArea,
			//         trigger: 'blur'
			//     }],
			// },
			assessClassList: [{
				id: '0',
				text: '本次估价过程中，估价对象所属小区无样本数据，模型使用的是其周边5公里内小区的数据作为样本数据进行估价，可能存在偏差，请慎重使用。'
			}, {
				id: '1',
				text: '本次估价过程中，估价对象所属小区无样本数据，模型使用的是其周边5公里内小区的数据作为样本数据进行估价，可能存在偏差，请慎重使用。'
			}, {
				id: '2',
				text: '本次估价过程中，估价对象所属小区无样本数据，模型使用的是其周边5公里内小区的数据作为样本数据进行估价，可能存在偏差，请慎重使用。'
			}, {
				id: '3',
				text: '本次估价过程中，估价对象所属小区无样本数据，模型使用的是其周边5公里内小区的数据作为样本数据进行估价，可能存在偏差，请慎重使用。'
			},],
			showInfo: false,

			//显示loading图
			isShowGif: false,

			canShowPrice: false,
			hasReason: false,
			//定时器
			timer: null,
		};
	},
	watch: {
		'$route'(newValue, oldValue) {
			if (newValue.query.id) {
				this.init(newValue.params)
			}
		},
		area: {
			handler(newVal, oldVal) {
				if (newVal) {
					if (newVal.shi=="440100000000") {
							this.isShowWxModel = true;
					}


				}

			},
			deep: true, // 深度监听
		},
		activeTab(newValue, oldValue) {
			if (newValue == '挂牌实例') {
				let _params = {
					event: 'click',
					isButton: true,
					log: '挂牌实例',
					os: navigator.userAgent,
					platform: '数据平台PC',
					route: this.$route.path,
					title: this.$route.meta.title,
					token: this.$store.state.token ? this.$store.state
						.token : '',
					user_id: this.$store.state.userId ? this.$store
						.state
						.userId : '',
				}
				data_user_operation_log_in(_params)
			}
		},
		//3s后强制蒙版消失
		spinShow(newValue, oldValue) {
			if (newValue) {
				// setTimeout(() => {
				// 	this.spinShow = false
				// }, 3000)
				this.$Spin.show()
				setTimeout(() => {
					// this.spinShow = false
					this.$Spin.hide()
				}, 3000)
			} else {
				this.$Spin.hide()
			}
		}
	},
	mounted() {
		this.loadMap(BMap);
		this.getHistory(1)
		this.getSelectHistory()
		this.setCityList()
	},
	methods: {
		wxModalChange(isShow) {
			if (!isShow) {
				this.area.shi = '440600000000'
				this.clearData()
				this.setMapCenter(this.area.shi)

			}
		},
		//获取风险提示
		getassessClassText(value) {
			let text = ''
			this.assessClassList.forEach(item => {
				if (item.id == value) {
					text = item.text
				}
			})
			return text
		},
		//当回显时需要的处理
		async init(data) {
			this.$refs.houseProperty.scrollIntoView()
			historyById({
				historyId: data.id
			}).then(async (res) => {
				//控制显示
				if (res.assessResult == 1) {
					this.canShowPrice = true
					this.hasReason = false
				} else if (res.assessResult == 0) {
					this.canShowPrice = false
					this.hasReason = false
				} else if (res.assessResult == 2) {
					this.canShowPrice = false
					this.hasReason = true
				}
				this.ypDetail = JSON.parse(data.feedBack)
				this.ypObj = res
				this.mapData.center.lng = this.ypObj.lng ? this.ypObj.lng : 113.335593
				this.mapData.center.lat = this.ypObj.lat ? this.ypObj.lat : 23.119767
				this.showInfo = true
				this.communityName = ''
				this.showCommunityName =
					`${this.ypObj.zstModelZpName} ${this.ypDetail.countyName ? this.ypDetail.countyName : ''}`
				if (this.ypDetail.provinceCode) {
					this.area.sheng = this.ypDetail.provinceCode
				}
				if (this.ypDetail.cityCode) {
					this.area.shi = this.ypDetail.cityCode
				}
				if (this.ypDetail.countyCode) {
					this.area.qu = this.ypDetail.countyCode
				}

				//拿接口返回单价、总价
				this.ypDetail.assessUnitPrice = res.assessUnitPrice ? res.assessUnitPrice : JSON.parse(
					data.feedBack).assessUnitPrice
				this.ypDetail.assessTotalPrice = res.assessTotalPrice ? res.assessTotalPrice : JSON
					.parse(data.feedBack).assessTotalPrice

				//获取历史
				this.getHistory(1)

				this.loadMap(BMap);
				this.GPdata = await this.getAl(0, this.ypDetail.assessBaseDate, this.ypDetail
					.zstCommunityId, this.ypDetail.assessUnitPrice)
				this.CJdata = await this.getAl(1, this.ypDetail.assessBaseDate, this.ypDetail
					.zstCommunityId, this.ypDetail.assessUnitPrice)
				this.$nextTick(() => {
					this.spinShow = false
				})
			})

		},
		loadMap(BMap) {
			let that = this;
			that.map = new BMap.Map("Bmap");
			const myPoint = new BMap.Point(
				that.mapData.center.lng,
				that.mapData.center.lat
			);
			that.map.centerAndZoom(myPoint, that.mapData.zoom);
			that.map.enableScrollWheelZoom(true);
			const icon = new BMap.Icon(that.liveIcon, new BMap.Size(52, 61), {
				anchor: new BMap.Size(25, 60)
			});
			icon.setSize(new BMap.Size(52, 61));
			icon.setImageSize(new BMap.Size(52, 61));
			that.map.enableScrollWheelZoom();
			let marker = new BMap.Marker(myPoint, {
				icon,
			});
			marker.addEventListener("click", () => {
				that.map.centerAndZoom(myPoint, 17);
				//点击时添加小区名字
			});
			that.map.addOverlay(marker);
			// 创建地址解析器实例
			// var myGeo = new BMap.Geocoder();
			// // 将地址解析结果显示在地图上,并调整地图视野
			// myGeo.getPoint(
			//   this.searchValue,
			//   function (point) {
			//     if (point) {
			//       // that.mapData.center = point;
			//       const myPoint = new BMap.Point(
			//         that.mapData.center.lng,
			//         that.mapData.center.lat
			//       );
			//       const icon = new BMap.Icon(that.liveIcon, new BMap.Size(50, 50));
			//       icon.setSize(new BMap.Size(50, 50));
			//       icon.setImageSize(new BMap.Size(50, 50));
			//       that.map.centerAndZoom(myPoint, 13);
			//       that.map.enableScrollWheelZoom();
			//       let marker = new BMap.Marker(myPoint, {
			//         icon,
			//       });

			//       marker.addEventListener("click", () => {
			//         that.map.centerAndZoom(myPoint, 14);
			//         //点击时添加小区名字
			//         // that.addInfo(that, myPoint);
			//       });
			//       that.map.addOverlay(marker);
			//       // that.initNearBySearch(myPoint); // 初始化周边搜索
			//     } else {
			//       alert("您选择地址没有解析到结果!");
			//     }
			//   },
			//   "中国"
			// );
		},
		openDetailChoose() {
			this.showDetail = !this.showDetail;
		},

		//面积验证
		validateArea(value, callback) {
			const reg = /^(([^0][0-9]+|0)\.([0-9]{1,4})$)|^([^0][0-9]+|0)$/;
			if (value === "") {
				callback("面积为必须项");
			} else if (!reg.test(value)) {
				callback("请输入正整数或小数，小数位数不超过4位");
			} else if (value >= 2000 || value <= 0) {
				callback("面积应大于0小于2000平方米");
			} else {
				callback(true);
			}
		},
		//修正和完善
		async detailChooseSure() {
			let params = JSON.parse(JSON.stringify(this.originData))
			this.validateArea(params.buildingArea, (res) => {
				if (res == true) {
					params.activateYears = params.activateYears.slice(0, 4)
					let addparams = {
						certificateInfo: params,
						platform: 1
					}
					certificateEdit(addparams).then((res) => {
						this.$msg.success({
							text: '提交成功，谢谢您的意见！系统以相关部门数据为准，并严格核验，您提交的信息我们将严格验证，准确无误后更新。'
						});
						this.openDetailChoose()
					}).catch(err => {
						this.$msg.error({
							text: err.data
						});
						this.openDetailChoose()
					})
				} else {
					this.$msg.error({
						text: res
					});
					this.openDetailChoose()
				}
			})
		},
		checkArea(area) {
			return new Promise((resolve, reject) => {
				this.validateArea(area, res => {
					resolve(res)
				})
			})
		},
		//估一估
		async assessment(again) {
			if (again) {
				if (this.lockBtn) return
				this.$router.push({
					path: "/Empowerment/pledge/houseProperty",
				});
				this.showInfo = false
				this.showDetail = true
				this.setBuildingArea = ''
				if (this.ypDetail.hasOwnProperty('assessTime')) {
					this.clearData()
				}
			} else {
				if (this.checked == false) {
					this.$msg.error({
						text: '请先阅读并勾选《房屋智能估价系统询价建议书》'
					})
					return
				}
				if (this.ypObj.id) {
					//处理提交的参数
					let params = {
						id: this.ypObj.id,
						platform: 1,
					}
					if (this.ypObj.buildingArea < 0) {
						let data = await this.checkArea(this.setBuildingArea)
						if (data == true) {
							params.buildingArea = this.setBuildingArea
						} else {
							this.$msg.error({
								text: data
							})
							return
						}
						// this.validateArea(this.setBuildingArea,res => {
						// 	if(res == true){
						// 		params.buildingArea = this.setBuildingArea
						// 	}else{
						// 		this.$msg.success({
						// 			text: res
						// 		})
						// 		return
						// 	}
						// })
					}
					this.lockBtn = true
					this.isShowGif = true
					this.ypDetail.assessTime = Date.parse(new Date())
					this.ypDetail.certificateAddress = this.ypObj.certificateAddress
					this.mapData.center.lng = this.ypObj.lng
					this.mapData.center.lat = this.ypObj.lat
					this.loadMap(BMap)
					setTimeout(() => {
						this.showInfo = true
						this.isShowGif = false
						this.lockBtn = false
					}, 3000)

					let houseDetail = await sampleResultDetail(params)
					if (houseDetail) {
						this.ypDetail = houseDetail
						//获取历史
						this.getHistory(1)
						//加载地址
						this.canShowPrice = this.ypDetail.canShow
						this.showInfo = true
						this.isShowGif = false
						//type（0挂牌，1成交）
						this.GPdata = await this.getAl(0, this.ypDetail.assessBaseDate, this.ypDetail.communityId,
							this.ypDetail.assessUnitPrice)
						this.CJdata = await this.getAl(1, this.ypDetail.assessBaseDate, this.ypDetail.communityId,
							this.ypDetail.assessUnitPrice)
					}


				} else {
					this.$msg.error({
						text: '房屋地址必填，请填写完整地址。'
					})
				}
			}

		},
		//获取案例
		async getAl(type, assessBaseDate, zstModelZpId, unitPrice) {
			let params = {
				current: 1,
				size: 5,
				type: type,
				assessBaseDate: assessBaseDate ? assessBaseDate : '',
				communityId: zstModelZpId ? zstModelZpId : '',
				unitPrice: unitPrice ? unitPrice : null
			}
			let data = await exampleList(params)
			return data.records || []
		},
		//模糊查询
		async getCommunityList(query) {
			if (query !== '') {
				if (this.timer) {
					clearTimeout(this.timer)
				}
				this.timer = setTimeout(async () => {
					this.loading = true;
					let params = {
						cityCode: this.area.shi,
						condition: query,
						provinceCode: this.area.sheng,
						isMobile: 1
					}
					let data = await communitySelectCondition(params)
					if (data) {
						const list = data.map(item => {
							return {
								value: `${item.communityName}&${query}`,
								label: `${item.communityName}-${item.countyName}`,
								communityInfo: JSON.stringify(item)
							}
						})
						this.communityList = list
						this.loading = false;
					}
				}, 500)

			} else {
				this.communityList = [];
			}
		},
		//当搜索词为空时，设置为历史
		onQueryChange(e) {
			this.showHistory = false
			if (e === '') {
				if (this.timer) {
					clearTimeout(this.timer)
				}
				this.getSelectHistory()
			}
		},
		//获取搜索历史
		async getSelectHistory() {
			let data = await communitySearchHistory()
			const list = data.map(item => {
				return {
					value: `${item.communityName}`,
					label: `${item.communityName}-${item.countyName}`,
					communityInfo: JSON.stringify(item)
				}
			})
			this.communityList = list
			this.showHistory = true
		},
		//选择
		selectCommunity(e) {
			// this.clearData()
			this.showCommunityName = `${e.label}`
			this.communityList.forEach(item => {
				if (e.value == item.value) {
					let obj = JSON.parse(item.communityInfo)
					if (obj.countyCode) {
						this.area.qu = obj.countyCode
					}
					if (obj.cityCode) {
						this.area.shi = obj.cityCode
					}
					if (obj.provinceCode) {
						this.area.sheng = obj.provinceCode
					}
					obj.type = 1
					//选择的时候添加历史记录
					saveCommunitySearchHistory(obj)
				}
			})
		},
		//删除
		deleteSelectHistory(obj) {
			let {
				id
			} = JSON.parse(obj.communityInfo)
			deleteHistory({
				id: id
			}).then(() => {
				this.getSelectHistory()
			})
		},
		//根据小区名字查找街道
		async searchStreet(e) {
			if (this.communityName) {
				let params = {
					cityCode: this.area.shi,
					communityName: this.communityName.split('&')[0],
					countyCode: this.area.qu,
					provinceCode: this.area.sheng,
					isMobile: 1,
				}
				let data = await certificateDetail_street(params)
				if (data) {
					const list = data.map(item => {
						return {
							value: item.street,
							label: item.street
						}
					})
					this.streetList = list
				} else {
					this.streetList = []
				}

			}
		},
		//根据街道查找单元
		async searchUnit(e) {
			//先注释掉查找单元掉接口，直接查找房号
			this.searchHouseNumber()
			// if (this.street) {
			// 	let params = {
			// 		cityCode: this.area.shi,
			// 		communityName: this.communityName.split('&')[0],
			// 		countyCode: this.area.qu,
			// 		provinceCode: this.area.sheng,
			// 		street: this.street,
			// 		isMobile: 1,
			// 	}
			// 	let data = await certificateDetail_unit(params)
			// 	if (data.length != 0) {
			// 		const list = data.map(item => {
			// 			return {
			// 				value: item.unit,
			// 				label: item.unit
			// 			}
			// 		})
			// 		this.unitList = list
			// 	} else {
			// 		this.searchHouseNumber()
			// 	}

			// } else {
			// }
		},
		//根据单元查找房号
		async searchHouseNumber() {
			let params = {
				cityCode: this.area.shi,
				communityName: this.communityName.split('&')[0],
				countyCode: this.area.qu,
				provinceCode: this.area.sheng,
				street: this.street,
				unit: this.unit,
				isMobile: 1,
			}
			let data = await certificateDetail_houseNumber(params)
			if (data) {
				this.houseDetaillist = data
				const list = data.map(item => {
					return {
						value: item.houseNumber,
						label: item.houseNumber
					}
				})
				this.houseNumberList = list
			}
		},
		showHouse(e) {
			this.houseDetaillist.forEach(item => {
				if (item.houseNumber == e.value) {
					this.ypObj = item.certificateInfo
				}
				//把信息加载到修改页面，用深拷贝，使原数据不修改
				this.originData = JSON.parse(JSON.stringify(this.ypObj))
				if (this.originData.activateYears) {
					this.originData.activateYears = `${this.originData.activateYears}-01-01`
				}
			})
		},
		clearData() {
			this.area.qu = ''
			this.showCommunityName = ''
			this.houseDetaillist = []
			this.houseNumber = undefined
			this.houseNumberList = []
			this.unit = ''
			this.unitList = []
			this.street = ''
			this.streetList = []
			this.loading = false
			this.originData = {}
			this.ypDetail = {}
			this.ypObj = {}
			this.communityName = ''
			this.communityId = ''
			this.communityList = []
			this.GPdata = []
			this.CJdata = []
			this.mapData = {
				zoom: 17,
				center: {
					lng: 113.335593,
					lat: 23.119767,
				}
			}
			this.showInfo = false,
				//显示loading图
				this.isShowGif = false,
				this.canShowPrice = false,
				this.loadMap(BMap);
			this.getSelectHistory()
		},
		//日期选择后的函数
		getHistorySelectDate(date) {
			this.getHistoryForm.assessTimeStart = date[0]
			this.getHistoryForm.assessTimeEnd = date[1]
		},
		//获取历史
		async getHistory(current) {
			this.getHistoryForm.current = current
			let params = this.getHistoryForm
			let data = await evaluationHistoryPage(params)
			this.Mydata = data.records
			this.getHistoryForm.current = data.current
			this.totalPage = data.total
		},
		//翻页
		changePage(current) {
			this.getHistory(current)
		},
		//反馈
		feedBack(e) {
			this.feedbackState = `${e}`
			if (e == 0) {
				// this.addDataUserFeedback(e)
				this.$refs.feedbackModal.ok('0')
			} else {
				this.showFeedbackModal = true
			}
		},
		//跳转
		goLink() {
			this.$router.push({
				path: "/Empowerment/pledge/pledgeManage/taskAdd",
			});
		},
		setCityList() {
			this.cityList = CityList.cityList
		},
		selectCity(value) {
			if (value == '440100000000') {
				this.isShowWxModel = true;
			}
			this.clearData()
			this.setMapCenter(this.area.shi)
		},
		setMapCenter(cityCode) {
			let center = CityList.cityList.filter(item => {
				return item.value == cityCode
			})[0].center
			this.mapData.center.lng = center.lng
			this.mapData.center.lat = center.lat
			this.loadMap(BMap)
		},
		//下载报表
		downLoadReport() {
			this.$btnSysLog(1, 2)
			let {
				certificateAddress = '--',
				assessTime = '--',
				assessTotalPrice = 0,
				assessUnitPrice = 0,
			} = this.ypDetail
			let {
				buildingArea = 0,
				propertyType = '--',
				houseStyle = '--',
				floorAt = '--',
				totalFloor = '--',
				activateYears = '--',
				lng = 113.335593,
				lat = 23.119767,
			} = this.ypObj
			this.reportJson = {
				certificateAddress,
				assessTime,
				assessTotalPrice,
				assessUnitPrice,
				buildingArea,
				propertyType,
				houseStyle,
				floorAt,
				totalFloor,
				activateYears,
				lng,
				lat,
				GPdata: this.GPdata,
				CJdata: this.CJdata,
			}
			this.reportJson = JSON.stringify(this.reportJson)
			this.showReport = true
		},
		//显示/隐藏 Report
		setReportState(val) {
			this.$set(this.showReport, val)
		}
	},
};
</script>

<style lang="scss" scoped>
.wx-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-family: PingFangSC-Regular-, PingFangSC-Regular;
	font-weight: normal;

	.wx-title {
		color: #333333;
		margin-top: 25px;
		margin-bottom: 36px;
	}

	.wx-img {
		width: 250px;
		height: 250px;
		border: 1px solid #F7F7F7;
	}

	.wx-desc {
		color: #666666;
		margin-top: 16px;
		margin-bottom: 33px;
	}

	.wx-bit {
		width: 154px;
	}
}

.home {
	#Bmap {
		height: 500px;
		background: #eee;
	}



	.titleInfo {
		font-size: 18px;
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		text-align: left;
		color: #000000;
		position: relative;
		height: 27px;
		line-height: 27px;
		text-indent: 1em;
		margin-top: 50px;
	}

	.title {
		font-size: 18px;
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		text-align: left;
		color: #000000;
		position: relative;
		height: 27px;
		line-height: 27px;
		text-indent: 1em;
		margin-top: 50px;
		left: -20px;

		&:before {
			display: block;
			content: "";
			width: 5px;
			height: 20px;
			position: absolute;
			top: 4px;
			left: 0;
			background: #203c85;
		}
	}

	.info {
		margin-top: 30px;
	}

	.chuck {}

	.chuckLeft {
		padding: 50px;
		text-align: center;
		box-shadow: 0px 12px 24px 0px #e5e5e5;
		border: 1px solid #e5e5e5;
	}

	.zheli {
		color: #203c85;
		display: inline-block;
		background: linear-gradient(#203c85, #203c85) no-repeat;
		background-size: 100% 1px;
		background-position: 0 1.8em;
		text-shadow: 0.05em 0 #fff, -0.05em 0 #fff;
		cursor: pointer;
	}

	.Checkbox {
		position: absolute;
		bottom: 220px;
		left: 43%;
		width: 50%;
		transform: translateX(-50%);
	}

	.btn {
		background-color: #203c85 !important;
		border: none;
		outline: none;
	}

	.btn_active {
		background-color: grey !important;
	}

	.assessmentBtn {
		position: absolute;
		bottom: 140px;
		left: 50%;
		width: 60%;
		transform: translateX(-50%);
		height: 70px;

		&.black {
			bottom: 120px;
		}
	}

	.noCommunity {
		&:hover {
			color: #203c85;
		}
	}

	.moreBtn {
		position: absolute;
		bottom: 90px;
		left: 50%;
		width: 60%;
		transform: translateX(-50%);

		&.black {
			bottom: 90px;
		}
	}

	.priceBox {
		padding-top: 15px;
		padding-bottom: 30px;
		border-bottom: 1px solid #e5e5e5;

		.priceInfo {
			font-size: 16px;
			font-family: Source Han Sans CN, Source Han Sans CN-Medium;
			font-weight: 500;
			color: #333333;
			line-height: 36px;

			&.priceDetail {
				margin-top: 15px;
			}

			.price {
				font-size: 48px;
				font-family: Roboto, Roboto-Bold;
				font-weight: 700;
				text-align: left;
				color: #007882;
				line-height: 36px;
			}
		}

		.referTips {
			width: 70%;
			margin-left: 15%;
			margin-top: 15px;
			text-align: left;

		}

	}

	.noOfficialGuideUnitPrice {
		margin-top: 60px;
	}

	.risk {
		text-align: left;
		width: 90%;
		position: relative;
		left: 30px;
		margin-top: 20px;
		color: #343434;

		.icon {
			position: absolute;

		}

		&:before {
			display: block;
			content: "";
			width: 10px;
			height: 10px;
			position: absolute;
			top: 5px;
			left: -15px;
			background: #D93C23;
		}
	}

	.feedBack {
		text-align: right;
		font-size: 14px;
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 500;
		color: #000000;
		margin-top: 20px;
		padding-right: 40px;
	}

	.resultText {
		width: 100%;
		text-align: left;
		margin-top: 15px;
		font-size: 24px;
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		text-align: center;
		color: #333333;
		line-height: 36px;
	}

	.officialGuidePriceBox {
		margin-top: 30px;
		border-top: 1px dashed #e5e5e5;

		.officialGuidePrice {
			margin-top: 40px;
			width: 100%;
			font-size: 24px;
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			text-align: center;
			color: #333333;
			line-height: 36px;

			&.price {
				text-align: left;
				font-size: 19px;
			}
		}


	}


	.childTabs {
		/deep/ .ivu-tabs-bar {
			border: none;
			padding-left: 65%;
		}

	}

	.ypObjItem {
		width: 80%;
		text-align: center
	}

	.ypObjItemNoData {
		width: 80%;
		margin-top: 30px;
		margin-right: 80px;
	}

	.page {
		position: relative;
		text-align: right;
		margin-top: 30px;
	}

	.changeFont {
		font-size: 16px;
	}

	.assessClassStyle {
		color: #D93C23;

	}

	/deep/ .ivu-btn-primary {
		// border-color: #203c85;
	}

	/deep/ .ivu-tabs.ivu-tabs-card>.ivu-tabs-bar .ivu-tabs-tab-active {
		color: white;
		background-color: #203c85;

	}

	/deep/ .ivu-tabs-tab {
		font-size: 16px;

		&:hover {
			color: #203c85;
		}
	}

	/deep/ .ivu-tabs-tab .ivu-tabs-tab-active .ivu-tabs-tab-focused {
		color: #203c85;
	}

	/deep/.ivu-tabs-nav .ivu-tabs-tab-active {
		color: #203c85;
	}

	/deep/.ivu-tabs-ink-bar {
		background-color: #203c85;
	}

	/deep/ .ivu-page-item-active a,
	.ivu-page-item-active:hover a {
		color: #203c85;
	}

	/deep/.ivu-page-item-active {
		border-color: #203c85;

	}

	/deep/.ivu-page-item {
		&:hover {
			a {
				color: #203c85;
			}

			border-color:#203c85;
		}
	}

	/deep/.ivu-page-prev {
		&:hover {
			a {
				color: #203c85;
			}

			border-color:#203c85;
		}
	}

	/deep/.ivu-page-next {
		&:hover {
			a {
				color: #203c85;
			}

			border-color:#203c85;
		}
	}

	/deep/.ivu-page-item-jump-next {
		.ivu-icon.ivu-icon-ios-arrow-forward {
			color: #203c85;
		}
	}

	/deep/.ivu-table {
		font-size: 16px;
	}

	/deep/.ivu-form .ivu-form-item-label {
		font-size: 16px;
	}

	/deep/.ivu-form .ivu-form-item-content {
		font-size: 16px;
	}

	.loadingGif {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: url(../../assets/image/isLoding.gif);
		background-color: white;
		opacity: 0.9;
		background-position-x: -50px;
		z-index: 99999;
	}

	.noPrice {
		width: 100%;
		text-align: center;
		height: 400px;
		line-height: 400px;
		font-size: 27px;
		opacity: 1;
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		color: #0093db;
	}

	.noPriceReason {
		width: 100%;
		height: 400px;

		.text {
			text-align: center;
			font-size: 27px;
			opacity: 1;
			font-family: Source Han Sans CN, Source Han Sans CN-Medium;
			font-weight: 500;
			color: #0093db;
			margin-top: 180px;
		}

		.reason {
			font-size: 14px;
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			text-align: center;
			color: #999999;
		}
	}
}
</style>
