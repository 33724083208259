<!--
 * 方法说明
 * author: 时雨
 * description:
 * @param
 * @return
 * @createTime: 2/18/2022, 2:21:27 PM
  -->
<template>
	<Modal v-model="showModal" footer-hide :mask-closable="false" width='80'>
		<p slot="header" style="text-align:center">
			<span>要估价的房屋</span>
		</p>
		<!-- <Alert show-icon>说明：广州先试行，其他城市稍后</Alert> -->
		<Row :gutter="50">
			<Col span="12">
			<Form :model="addAdressForm" :rules="addAdressRules" style="margin-top:50px;" ref="addAdress"
				:label-width="80">
				<FormItem prop="cityCode" style="width: 80%;margin-left:10%;margin-top:30px" label="城市">
					<Select v-model='addAdressForm.cityCode' size="large" style="padding-right: 45px;">
						<Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
					</Select>
				</FormItem>
				<FormItem prop="communityName" style="width: 80%;margin-left:10%;" label="小区名称">
					<Input maxlength="15" show-word-limit size="large" style="padding-right: 45px;" v-model="addAdressForm.communityName"
						placeholder="小区名称"></Input>
				</FormItem>
				<FormItem prop="certificateAddress" style="width: 80%;margin-left:10%" label="证载地址">
					<Input maxlength="40" show-word-limit size="large" style="padding-right: 45px;" v-model="addAdressForm.certificateAddress"
						placeholder="详细证载/房屋地址（包括楼栋、房号）"></Input>
					<div>
						【示例：广州市天河区汇景北路154号XXXX房】
					</div>
				</FormItem>

				<FormItem prop="buildingArea" style="width: 80%;margin-left:10%;margin-top:30px" label="面积">
					<Input type="number" size="large" style="padding-right: 45px;" v-model="addAdressForm.buildingArea" placeholder="0-2000的数字"><span
						slot="append">平方米</span>
					</Input>
				</FormItem>
				<FormItem>
					<Checkbox v-model="addAdressFormCheck">
						此信息仅用于中思拓数据平台为用户提供服务，不作其它商业用途，我已知悉并自愿填写，查看<span @click='enterLogin'>《用户协议》《隐私政策》《版权声明》</span>
					</Checkbox>
				</FormItem>
			</Form>
			<Row style="margin-top:115px">
				<Button
					style="width:240px;position: relative;left: 50%;transform: translateX(-50%);margin-bottom:130px;border: none;outline: none;"
					:class="['btn',addAdressFormCheck?'btn_active':'']" :disabled="!addAdressFormCheck"
					@click="addAdressSubmit('addAdress')"
					v-track="{event:'click',route:$route,isButton:true,log:'找不到房屋-提交'}" type="primary"
					:loading='noCommunityLoading' size="large">提交</Button>
			</Row>
			</Col>
			<Col span="12">
			<p
				style="width:326px;margin-top:50px;font-size: 16px;font-family: Source Han Sans CN, Source Han Sans CN-Medium;font-weight: 500;text-align: left;color: #000000;line-height: 36px;">
				什么是证载地址？如图：</p>
			<img class="zzdzImg" style="width:90%;position: relative;left:5%;margin-top:30px;margin-bottom:20px"
				src="../assets/image/zzdz.gif" alt="">
			</Col>
		</Row>
	</Modal>
</template>

<script>
	import {
		certificateApply,
	} from "@/api/pledge";
	import CityList from '../views/pledge/cityList.js'
	export default {
		name: 'NoCommunity',
		props: {
			noCommunity: {
				type: Boolean,
				default: false
			},
			cityCode: {
				type: [String, Number],
				default: ''
			},
			communityName: {
				type: String,
				default: ''
			}
		},
		computed: {
			showModal: {
				get() {
					return this.noCommunity
				},
				set(val) {
					this.$parent.noCommunity = val
				}
			},
		},
		watch: {
			noCommunity: {
				handler(val) {
					if (val) {
						this.cityList = CityList.cityList
						this.addAdressForm.cityCode = this.cityCode
						this.addAdressForm.communityName = this.communityName.split('&')[0]
					}
				},
				immediate: true
			},
		},
		data() {
			const validatebuildingArea = (rule, value, callback) => {
				const reg = /^\d{0,1}(\d{0,4})\.{0,1}(\d{1,4})?$/ig;
				if (value === '') {
					callback(new Error('面积为必选项'));
				} else if (value > 2000 || value < 0) {
					callback(new Error('面积应大于0小于2000平方米'));
				} else if (!reg.test(value)) {
					callback(new Error('请输入正整数或小数，小数位数不超过4位'));
				} else {
					callback();
				}
			};
			return {
				//添加证载地址
				addAdressForm: {
					cityCode: '',
					certificateAddress: '',
					communityName: '',
					buildingArea: '',
					isMobile: 1,
				},
				//添加证载地址的校验
				addAdressRules: {
					cityCode: [{
						required: true,
						message: '必选项',
					}],
					certificateAddress: [{
						required: true,
						message: '必填项',
						trigger: 'blur'
					}],
					communityName: [{
						required: true,
						message: '必填项',
						trigger: 'blur'
					}],
					buildingArea: [{
						required: true,
						validator: validatebuildingArea,
						trigger: 'blur'
					}],
				},
				addAdressFormCheck: false,
				//找不到小区loading
				noCommunityLoading: false,
				cityList: [],
			}
		},
		methods: {
			//添加证载地址
			addAdressSubmit(name) {
				this.$refs[name].validate((valid) => {
					if (valid) {
						let params = this.addAdressForm
						this.noCommunityLoading = true
						certificateApply(params).then(res => {
							this.showModal = false
							this.addAdressForm = {
								certificateAddress: '',
								communityName: '',
								buildingArea: '',
								isMobile: 1,
							}
							this.$msg.success({
								text: "成功提交，估价结果完成后，我们将以短信通知。"
							});
							this.$emit('getHistory', 1)
							// this.grtHistory(1)
							this.noCommunityLoading = false
						})
					} else {
						this.$msg.error({
							text: "数据不完整"
						});
					}
				})
			},
			enterLogin() {
				this.disclaimerModal = true;
			},
		},
	}
</script>

<style scoped>

</style>
