<template>
	<Modal v-model="showModal" footer-hide :mask-closable="false" fullscreen transfer>
		<div slot="close" class="modelClose">X</div>
		<div ref="report" class="wrapper">
			<div class="bigTitle">
				房屋智能估价结果
			</div>
			<div class="chuck">
				<div class="title">
					估价结果
				</div>
				<div class="cbox">
					<div class="baseInfo">
						<div class="option">
							<div class="item label">估价对象</div>
							<div class="item value">{{reportData.certificateAddress | formateValue}}</div>
						</div>
						<div class="option">
							<div class="item label">估价基准日</div>
							<div class="item value">{{reportData.assessTime | changeDate}}</div>
						</div>
					</div>
					<div class="priceBox">
						<div class="price">
							<div class="item label">价格</div>
							<div class="item value">
								<span class="num">{{reportData.assessUnitPrice.toFixed(0) | formateValue}}</span>元/平米
							</div>
						</div>
						<div class="price">
							<div class="item label">总价</div>
							<div class="item value">
								<span class="num">{{(reportData.assessTotalPrice*1/10000).toFixed(2)}}</span>万元
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="chuck detailBox">
				<div class="title">
					房屋详情
				</div>
				<div class="cbox">
					<div class="baseInfo">
						<div class="option">
							<div class="item label">面积</div>
							<div class="item value">{{reportData.buildingArea | formateValue}}平方米</div>
						</div>
						<div class="option">
							<div class="item label">物业类型</div>
							<div class="item value">{{reportData.propertyType | formateValue}}</div>
						</div>
						<div class="option">
							<div class="item label">房屋户型</div>
							<div class="item value">{{reportData.houseStyle | formateValue}}</div>
						</div>
						<div class="option">
							<div class="item label">楼层</div>
							<div class="item value">{{reportData.floorAt | formateValue}}层</div>
						</div>
						<div class="option">
							<div class="item label">总楼层</div>
							<div class="item value">{{reportData.totalFloor | formateValue}}层</div>
						</div>
						<div class="option">
							<div class="item label">建成年代</div>
							<div class="item value">{{reportData.activateYears | formateValue}}年</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="showMap" class="chuck mapBox">
				<div class="title">
					房屋位置
				</div>
				<div class="Bmap" id="Bmap1"></div>
			</div>
			<div class="chuck Instance">
				<div class="title">
					参考实例
				</div>
				<div class="InstanceBox">
					<div class="dealInstance">
						<div class="mintitle">
							成交实例
						</div>
						<Table :columns="CJcolumns" :data="reportData.CJdata"></Table>
					</div>
					<div class="listedInstance">
						<div class="mintitle">
							挂牌实例
						</div>
						<Table :columns="GPcolumns" :data="GPdata"></Table>
					</div>
				</div>

			</div>
			<div class="chuck statementBox">
				<div class="title">
					免责声明
				</div>
				<div class="statement">
					本报告为房屋智能估价根据数据和算法自动生成，并未对估价对象进行现场勘察，中思拓研究院不承担对估价对象质量、特殊情况及其他方面进行勘察、检测的责任。本报告及其结论不应当被认为是对询价对象可实现价格的保证，不作为任何交易建议和交易估价的依据，中思拓研究院并不承担相关当事人决策和交易的责任。
				</div>
			</div>
			<div class="watermark"></div>
		</div>
		<!-- <div class="watermark">99</div> -->
	</Modal>
</template>

<script>
	import {
		formatDate,
	} from "@/utils/";
	import BMap from "BMap";
	import html2canvas from "html2canvas";
	import jsPdf from "jspdf";
	import _ from 'lodash'
	export default {
		name: 'Report',
		props: {
			showReport: {
				type: Boolean,
				default: false
			},
			reportJson: {
				type: String,
				default: ''
			}
		},
		filters: {
			changeDate(value) {
				if (value) {
					return formatDate(value * 1)
				} else {
					return ''
				}
			},
			formateValue(value) {
				return value ? value : '--'
			}

		},
		computed: {
			showModal: {
				get() {
					return this.showReport
				},
				set(val) {
					this.$parent.showReport = val
					if (!val) {
						// this.reportData = ''
					}
				}
			},
			reportData: {
				get() {
					if (this.reportJson) {
						return JSON.parse(this.reportJson)
					} else {
						return {
							certificateAddress: '--',
							assessTime: '--',
							assessUnitPrice: '--',
							assessTotalPrice: '--',
							buildingArea: '--',
							propertyType: '--',
							houseStyle: '--',
							floorAt: '--',
							totalFloor: '--',
							activateYears: '--',
						}
					}
				},
				set(val) {
					this.$parent.reportJson = JSON.stringify(val)
				}
			},
			GPcolumns() {
				let arr = _.cloneDeep(this.$parent.GPcolumns)
				return arr.map(item => {
					item.width = ''
					return item
				})
			},
			CJcolumns() {
				let arr = _.cloneDeep(this.$parent.CJcolumns)
				return arr.map(item => {
					item.width = ''
					return item
				})
			},
			CJdata(){
				return this.reportData.CJdata.slice(0,3)
			},
			GPdata(){
				return this.reportData.GPdata.slice(0,3)
			}
		},
		watch: {
			showModal(val) {
				this.$emit('showReport',val)
				if (val) {
					this.showMap = true
					this.showSpain = true
					if (this.reportData.lng && this.reportData.lat) {
						setTimeout(() => {
							this.loadMap(BMap, this.reportData.lng, this.reportData.lat)
						}, 100)
					}else{
						this.showMap = false
					}
					//下载pdf
					setTimeout(() => {
						this.toImage()
					}, 1000)
				} else {
					this.map = null
					this.showMap = false
				}
			},
			showSpain(val){
				if(val){
					this.$Spin.show()
				}else{
					this.$Spin.hide()
				}
			}
		},
		data() {
			return {
				map: null,
				mapData: {
					zoom: 17,
					center: {
						lng: 113.335593,
						lat: 23.119556,
					},
				},
				liveIcon: require("@/assets/image/map/local.png"),
				showMap:false,
				showSpain:false,
			}
		},
		methods: {
			loadMap(BMap, lng, lat) {
				let that = this;
				that.map = new BMap.Map("Bmap1");
				const myPoint = new BMap.Point(
					lng,
					lat,
				);
				that.map.centerAndZoom(myPoint, 17);
				const icon = new BMap.Icon(that.liveIcon, new BMap.Size(52, 61), {
					anchor: new BMap.Size(25, 60)
				});
				icon.setSize(new BMap.Size(52, 61));
				icon.setImageSize(new BMap.Size(52, 61));
				let marker = new BMap.Marker(myPoint, {
					icon,
				});
				that.map.addOverlay(marker);
			},
			toImage() {
				// 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
				html2canvas(this.$refs.report, {
					scale: 2,
					useCORS: true,
					allowTaint: true,
					// taintTest: false,
					imageTimeout: 0,
				}).then((canvas) => {
					// 把生成的base64位图片上传到服务器,生成在线图片地址
					this.htmlToPdf(canvas);
				});
			},
			async htmlToPdf(htmlCanvas) {
				// 将canvas对象转为pdf
				const pdf = this.canvasToPdf(htmlCanvas);
				// 通过浏览器下载pdf
				this.downPdf(pdf, this.reportData.certificateAddress);
			},
			downPdf(pdfInstance, title) {
				// 文件名过长导致下载失败
				if (title.length > 50) {
					title = title.substring(title.length - 50);
				}
				pdfInstance.save(title + ".pdf", {
					returnPromise: true
				}).then(() => {
					// 搜狗浏览器下载机制问题暂时不关闭
					// if (!(navigator.userAgent.toLowerCase().indexOf("se 2.x") > -1)) {
					// 	setTimeout(window.close, 300);
					// }
					this.showSpain = false				
				});
			},
			canvasToPdf(htmlCanvas) {
				const canvasWidth = htmlCanvas.width;
				const canvasHeight = htmlCanvas.height;
				const imgBase64 = htmlCanvas.toDataURL("image/jpeg", 1.0);

				// a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
				const imgWidth = 595.28;
				// 图片高度需要等比缩放
				const imgHeight = (595.28 / canvasWidth) * canvasHeight;

				let pageHeight = imgHeight; // pdf转化后页面总高度
				let position = 0;

				const pdfInstance = new jsPdf("", "pt", "a4");
				pdfInstance.setFontSize(12);

				if (imgHeight < 841.89) {
					pdfInstance.addImage(imgBase64, "JPEG", 0, 0, imgWidth, imgHeight);
				} else {
					while (pageHeight > 0) {
						pdfInstance.addImage(
							imgBase64,
							"JPEG",
							0,
							position,
							imgWidth,
							imgHeight
						);
						pageHeight -= 841.89;
						position -= 841.89;
						if (pageHeight > 0) {
							pdfInstance.addPage();
						}
					}
				}
				return pdfInstance;
			},
		},
	}
</script>

<style lang="scss" scoped>
	.watermark{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: url('../assets/image/watermark.png') repeat-y;
		height: 100%;
		width: 100%;
		background-position: center bottom;
		pointer-events: none;
		// background-color: #007882;
	}
	.wrapper {
		position: relative;
		// background: url('../assets/image/watermark.png') repeat-y;
		padding-top: 40px;
	}

	.bigTitle {
		font-size: 48px;
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		text-align: center;
		color: #333333;
		margin-bottom: 40px;
	}

	.mintitle {
		font-size: 16px;
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		text-align: left;
		color: #333333;
		margin: 40px 0 15px 0;
	}

	.chuck {
		width: 1100px;
		position: relative;
		left: 50%;
		transform: translate(-50%);
		background: transparent;
		border-radius: 8px;
		box-shadow: -4px 0px 24px 0px rgba(51, 51, 51, 0.1);
		padding: 20px 60px;
		margin-bottom: 20px;
		border: 1px solid rgba(#999999, 0.5);

		.title {
			position: relative;
			font-size: 18px;
			font-family: Source Han Sans CN, Source Han Sans CN-Medium;
			font-weight: 500;
			text-align: left;
			color: #333333;

			&:before {
				position: absolute;
				content: '';
				width: 5px;
				height: 20px;
				background: #1eaef5;
				left: -20px;
				top: 5px;
			}
		}
	}

	.baseInfo {
		position: relative;
		padding: 30px 50px;
		border-bottom: 1px solid rgba(#999999, 0.5);
	}

	.option {
		font-size: 0;
		position: relative;
		padding: 15px 0;
		width: 450px;
		margin: auto;

		.item {
			display: inline-block;
			vertical-align: middle;
			font-size: 14px;
		}

		.label {
			width: 100px;
			font-size: 16px;
			font-family: Source Han Sans CN, Source Han Sans CN-Medium;
			font-weight: 500;
			text-align: right;
			color: #999999;
		}

		.value {
			margin-left: 30px;
			max-width: 320px;
			font-size: 14px;
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			color: #333333;
		}
	}

	.priceBox {
		font-size: 0;
		position: relative;
		padding: 30px 0;
		width: 600px;
		margin: auto;

		.price {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			font-size: 14px;
			width: 300px;

			.label {
				font-size: 16px;
				font-family: Source Han Sans CN, Source Han Sans CN-Medium;
				font-weight: 500;
				color: #999999;
			}

			.value {

				.num {
					font-size: 36px;
					font-family: Arial, Arial-Bold;
					font-weight: 700;
					color: #007882;
				}
			}
		}
	}

	.detailBox {
		.baseInfo {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			border-bottom: none
		}

		.option {
			width: 300px;

			.value {
				max-width: 100px;
			}
		}

	}

	#Bmap1 {
		margin-top: 40px;
		margin-bottom: 40px;
		height: 500px;
		width: 700px;
		background: #eee;
		position: relative;
		left: 50%;
		transform: translate(-50%);
	}

	.Instance {
		.InstanceBox {
			padding-bottom: 40px;
		}
	}

	.statementBox {
		.statement {
			padding: 40px 0px;
			text-indent: 2em;
			font-size: 18px;
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			text-align: left;
			color: #999999;
		}
	}
	.modelClose{
		line-height: 40px;
		color: #ffffff;
		width: 40px;
		height: 40px;
		margin: 10px 100px;
		text-align: center;
		border-radius: 50%;
		background: red;
	}
</style>
